import * as React from "react"
import { Link, HeadFC } from "gatsby"
import Layout from "../components/layout/layout"
import Contact from "../components/contact/contact"
import SEO from "../components/seo/seo"

const ContactPage = () => {
  return (
    <Layout chat={true}>
        <Contact/>        
    </Layout>
  )
}

export default ContactPage

export const Head: HeadFC = () => <SEO title="Contact us"/>
