import * as React from "react"
import { Link, HeadFC } from "gatsby"
import strings from "../../i18n"
import axios from "axios"

import Button from "../button/button"

import {API_URL} from "../../../config/env"

import thankyou from "../../images/bg_thank_you.svg"

import {getCookieValue} from "../../helpers/cookies"
import {validateEmail} from "../../helpers/email"
import {trackEvent} from "../../helpers/analytics"

import "./contact.scss"

type ContactProps = {

};
type ContactState = {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    comments: string;
    submitted: boolean;
};

class Contact extends React.Component<ContactProps, ContactState> {
    state: ContactState = {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      comments: "",
      submitted: false,
    };

    clear(){
      const invalids = document.querySelectorAll(".invalid");
      invalids.forEach((invalid) => {
        invalid.classList.remove("invalid")
      });
    }

    strip_non_digits(val : string) : string {
      return val.replace(/[^0-9.]/g,'')
    }

    async onSubmit(){
      this.clear()
      const firstnameElement = document.querySelector(".first_name");
      const lastnameElement = document.querySelector(".last_name");
      const emailElement = document.querySelector(".email");
      const phoneElement = document.querySelector(".phone");
      const livingSituationElement = document.querySelector(".living_situation");
      if (this.state.first_name == ""){
        firstnameElement?.classList.add("invalid")
      }
      if (this.state.last_name == ""){
        lastnameElement?.classList.add("invalid")
      }
      if (this.state.email == ""){
        emailElement?.classList.add("invalid")
      }
      if (!validateEmail(this.state.email)){
        emailElement?.classList.add("invalid")
      }
      if (this.state.phone == ""){
        phoneElement?.classList.add("invalid")
      }
      const phoneVal = this.strip_non_digits(this.state.phone)
      if (phoneVal.length < 10){
        phoneElement?.classList.add("invalid")
      }
      const invalids = document.querySelectorAll(".invalid");
      if (invalids.length > 0){
        return;
      }
      const apiURL = API_URL + "/contact"
      try{
        await axios({
          method: 'post',
          url: apiURL,
          data: 
          {
            firstname: this.state.first_name,
            lastname: this.state.last_name,
            email: this.state.email,
            phone: this.state.phone,
            lead_form_comments: this.state.comments,
            hubspot: getCookieValue("hubspotutk")
          }
        })
        this.setState({submitted: true})
        trackEvent("contactform")
      }catch(err){
        console.error(err)
      }
    }

    render() {
      if (this.state.submitted){
        return (
          <div id="contact" className="contact section">
            <div className="container">
            <img src={thankyou} />
          <h1>
            Thank you!
          </h1>

          <p className="body1">
            Please look out for an email with next steps.
          </p>
          </div>
        </div>
        )
      }
      return (
        <div id="contact" className="contact section">
          <div className="container">
            <h1>{strings.contact_us}</h1>
            <form>
              <div className="field first_name">
                <div className="body1">{strings.first_name}</div>
                <input type="text" id="firstName" value={this.state.first_name} onChange={(event)=> {this.setState({first_name: event.target.value})}}/>
              </div>
              <div className="field last_name">
                <div className="body1">{strings.last_name}</div>
                <input type="text" id="lastName"  value={this.state.last_name} onChange={(event)=> {this.setState({last_name: event.target.value})}}/>
              </div>
              <div className="field email">
                <div className="body1">{strings.email}</div>
                <input type="email" id="email"  value={this.state.email} onChange={(event)=> {this.setState({email: event.target.value})}}/>
              </div>
              <div className="field phone">
                <div className="body1">{strings.phone}</div>
                <input type="phone" id="phone"  value={this.state.phone} onChange={(event)=> {this.setState({phone: event.target.value})}}/>
              </div>
              <div className="field comments full-row">
                <div className="body1">{strings.contact_form_question}</div>
                <textarea id="comments" placeholder={strings.contact_form_placeholder}  value={this.state.comments} onChange={(event)=> {this.setState({comments: event.target.value})}}></textarea>
              </div>
            </form>
            <Button onClick={this.onSubmit.bind(this)}>{strings.submit}</Button>
          </div>   
        </div>
      )
    }
}

export default Contact
